import Vue from 'vue'
import VueRouter from 'vue-router'
import ClientView from '../views/HomeView.vue'
import MaintenanceView from '../views/MaintenanceView.vue'
import ClientSelectorView from '../views/ClientSelectorView.vue'
import UnassignedViewerView from '../views/UnassignedViewerView.vue'
import NotFoundView from '../views/NotFoundView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/details/:id', // Add the id parameter to the path
        name: 'clientView',
        component: ClientView,
    },
    {
        path: '/maintenance',
        name: 'maintenanceView',
        component: MaintenanceView,
    },
    {
        path: '/',
        name: 'clientSelector',
        component: ClientSelectorView,
    },
    {
        path: '/unassigned',
        name: 'unassignedViewer',
        component: UnassignedViewerView,
    },
    { path: '/:pathMatch(.*)*', component: NotFoundView },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
