<template>
  <v-container fluid>
    <UnassignedPersonViewer
      :showReturnButton="true"
      :customStyle="'height: 90vh; overflow: auto'"
    />
  </v-container>
</template>

<script>
import router from "@/router";
import UnassignedPersonViewer from "../components/UnassignedPersonViewer";
export default {
  name: "UnassignedVierwerView",
  components: { UnassignedPersonViewer },
  computed: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
