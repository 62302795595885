<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :loading="loading"
        loader-height="0"
        fixed-header
        hide-default-footer
        :headers="headers"
        :items="invoices"
        :items-per-page="rows"
        item-key="invoiceNumber"
      >
        <template v-slot:item.invoiceAds.FirstReference="{ item }">
          <div v-if="item.invoiceAds.FirstReference != ' '">
            {{ item.invoiceAds.FirstReference }}
          </div>
          <div v-else>{{ item.invoiceAds.InvoiceNumber }} (no first ref.)</div>
        </template>
        <template v-slot:item.isPaid="{ item }">
          <v-icon v-if="item.isPaid" color="green" small> mdi-check </v-icon>
          <v-icon v-else color="red" small> mdi-close </v-icon>
        </template>
        <template v-slot:item.notes="{ item }">
          <v-text-field
            v-model="item.notes"
            hide-details
            outlined
            dense
            @change="updateInvoice(item)"
            :disabled="editLoading"
          />
        </template>
        <template v-slot:item.licenceCount="{ item }">
          <v-text-field
            v-model="item.licenceCount"
            hide-details
            outlined
            dense
            single-line
            @change="updateInvoice(item)"
            type="number"
            :disabled="editLoading"
          />
        </template>
        <template v-slot:item.licenceRate="{ item }">
          <div v-if="item.licenceCount > 0">
            {{ (item.invoiceAds.FeeAmount / item.licenceCount).toFixed(2) }}
          </div>
          <div v-else style="color: red">&lt;- Licence no. invalid</div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon color="black" small @click="openNewTabToFeePortal(item)">
            mdi-arrow-expand-right
          </v-icon>
        </template>
        <template v-slot:loading>
          <div style="padding: 20px">
            <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <h1>Loading...</h1>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <div class="text-center">
      <br />
      <v-btn
        color="green"
        dark
        v-if="rows == 3"
        :disabled="limit"
        @click="setTableLimit(-1)"
        >See all invoices</v-btn
      >
      <v-btn color="green" v-if="rows == -1" @click="setTableLimit(3)"
        >Hide full invoice list</v-btn
      >
    </div>
    <v-dialog v-model="dialog" width="50vw">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ dialogHeadingComputed }}
        </v-card-title>

        <v-card-text>
          <div v-for="(item, key, index) in currentDetail">
            {{ key }} - {{ item }}
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="editLoading">
      {{ loadingText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import msalAuthHandler from "vue-msal-wrapper";
export default {
  props: ["clientInvoices", "loading"],
  data: () => ({
    dialog: false,
    headers: [
      { text: "Job Code", value: "invoiceAds.JobCode" },
      // { text: "Invoicing Entity", value: "invoiceAds.InvoicingEntity" },
      { text: "External Invoice Number", value: "invoiceAds.FirstReference" },
      { text: "Notes", value: "notes" },
      { text: "Licences", value: "licenceCount" },
      { text: "$/Licence", value: "licenceRate" },
      { text: "Fee Amount ($ AUD)", value: "invoiceAds.FeeAmount" },
      { text: "Paid?", value: "isPaid" },
      { text: "Date Issued", value: "invoiceAds.InvoiceDate" },
      { text: "Fee Portal", value: "actions", sortable: false },
    ],
    currentDetail: {},
    dialogHeading: "",
    rows: 3,
    editLoading: false,
    loadingText: "Updating invoice...",
  }),
  mounted() {},
  methods: {
    openNewTabToFeePortal(item) {
      window.open(
        `https://feeportal.arup.com/arupfeeportal/viewinvoicedetail.aspx?jobno=${item.invoiceAds.JobCode}&invno=${item.invoiceAds.InvoiceNumber}`,
        "_blank"
      );
    },
    setTableLimit(limit) {
      this.rows = limit;
    },
    closeDialog() {
      this.dialog = false;
    },
    openDialog(item) {
      this.currentDetail = item;
      this.dialog = true;
    },
    updateInvoice(item) {
      this.editLoading = true;
      let payload = {
        licenceCount: parseInt(item.licenceCount, 10),
        licenceRate: parseInt(
          (item.invoiceAds.FeeAmount / item.licenceCount).toFixed(2),
          10
        ),
        notes: item.notes,
      };
      msalAuthHandler
        .authenticatedApi(this.$store.state.apiUrl, "backend")
        .patch(`invoice/${item.id}`, JSON.stringify(payload))
        .then((res) => {
          console.log(res);
          if (res.status !== 200) {
            throw new Error("Network response was not ok");
          }
          this.editLoading = false;
        });
    },
  },
  computed: {
    limit() {
      return this.clientInvoices.length <= 3;
    },
    dialogHeadingComputed() {
      return this.dialogHeading;
    },
    invoices() {
      if (this.clientInvoices.length > 0) {
        return this.clientInvoices.sort(
          (a, b) =>
            new Date(b.invoiceAds.InvoiceDate) -
            new Date(a.invoiceAds.InvoiceDate)
        );
      } else {
        return [];
      }
    },
  },
};
</script>
