<template>
  <v-container style="padding-top: 10px">
    <div v-if="graphData.length > 0">
      <v-row style="padding: 10px; border: 1px solid #ccc; border-radius: 5px">
        <v-col cols="3">
          Total function used: <br />
          <strong> {{ totalFunctions }} </strong>
        </v-col>
        <v-col cols="3">
          Average function per tag:<br />
          <strong> {{ averageFunctionPerTag }} </strong>
        </v-col>
        <v-col cols="3">
          Total unique tags:<br />
          <strong> {{ totalUniqueTags }} </strong>
        </v-col>
        <v-col cols="3">
          Total unique users:<br />
          <strong> {{ totalUniqueUsers }} </strong>
        </v-col>
      </v-row>
      <v-row style="padding: 10px; padding-top: 20px">
        <!-- <ccv-simple-bar-chart
          :data="graphData"
          :options="options"
        ></ccv-simple-bar-chart> -->

        <pure-vue-chart
          :points="newGraphData"
          :width="chartWidth"
          :height="220"
          :show-y-axis="false"
          :show-x-axis="true"
          :bar-color="'#167595'"
          :show-values="true"
        />
      </v-row>
    </div>
  </v-container>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "@carbon/styles/css/styles.css";
import "@carbon/charts/styles.css";
import PureVueChart from "pure-vue-chart";

export default {
  components: {
    "v-chart": ECharts,
    PureVueChart,
  },
  props: ["clientUsageData"],
  data: () => ({
    options: {
      title: "",
      tooltip: {
        trigger: "item",
        showContent: false,
      },
      color: {
        scale: {
          "function calls": "#167595",
        },
      },
      axes: {
        left: {
          mapsTo: "functionCount",
          // scaleType: "labels"
        },
        bottom: {
          mapsTo: "date",
          scaleType: "time",
        },
      },
      toolbar: {
        // turns off the options in top right corner
        controls: [],
      },
      // height: "400px",
      resizable: true,
    },
  }),
  mounted() {
    console.log(this.graphData);
  },
  methods: {},
  computed: {
    // calculate width of the chart wrt to the window width
    chartWidth() {
      return window.innerWidth - 200;
    },
    newGraphData() {
      let simplified = [];
      if (this.clientUsageData.access) {
        this.clientUsageData.access.forEach((element, index) => {
          if (index % 2 === 0 || this.clientUsageData.access.length <= 12) {
            let tempobj = {};
            tempobj["value"] = element.FunctionCount;
            tempobj["label"] = new Date(element.date).toLocaleString("en-US", {
              month: "short",
              year: "2-digit",
            });
            simplified.push(tempobj);
          } else {
            let tempobj = {};
            tempobj["value"] = element.FunctionCount;
            tempobj["label"] = "";
            simplified.push(tempobj);
          }
        });
      }

      return simplified;
    },
    graphData() {
      let simplified = [];
      if (this.clientUsageData.access) {
        this.clientUsageData.access.forEach((element) => {
          let tempobj = {};
          tempobj["group"] = element.group;
          tempobj["functionCount"] = element.FunctionCount;
          tempobj["date"] = element.date;
          simplified.push(tempobj);
        });
      }
      return simplified;
    },
    totalFunctions() {
      return this.clientUsageData.totalFunctions;
    },
    averageFunctionPerTag() {
      return this.clientUsageData.averageFunctionPerTag;
    },
    totalUniqueTags() {
      return this.clientUsageData.totalUniqueTags;
    },
    totalUniqueUsers() {
      return this.clientUsageData.totalUniqueUsers;
    },
  },
};
</script>
<style>
.my-chart {
  width: 800px;
  height: 500px;
}
</style>
