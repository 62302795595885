<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <ClientViewer></ClientViewer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ClientList from "../components/ClientList";
import ClientViewer from "../components/ClientViewer";
import msalAuthHandler from "vue-msal-wrapper";

export default {
  name: "ClientView",
  mounted() {
    /**
     * If the client is not present, redirect to the home page.
     */
    // if (!this.clientPresent) {
    //   this.$router.push("/");
    // }
  },
  computed: {
    // clientPresent() {
    //   if (this.$store.state.activeClient.jobCode.length > 0) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  components: {
    ClientList,
    ClientViewer,
  },
  data() {
    return {
      clients: [],
      loading: false,
    };
  },
  created() {},
};
</script>
