import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#167595",
                background: "#cccccc",
            },
            dark: {
                primary: "#E61E28",
                background: "#cccccc",
            },
        },
    },
});
