<template>
  <v-container fluid>
    <v-card style="customStyle">
      <v-container v-if="showReturnButton" fluid>
        <v-row>
          <v-col cols="10" class="d-flex justify-left align-center"> </v-col>
          <v-col cols="2">
            <v-btn color="red" dark @click="returnToClientList()">
              <v-icon>mdi-arrow-left</v-icon>
              BACK TO LIST
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-text-field
        outlined
        v-model="search"
        label="Filter unassigned users"
        single-line
        clearable
        @click:clear="clearSearch"
        hide-details
      ></v-text-field>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :search="search"
        :items="unassignedPersons"
        dense
        :hide-default-footer="false"
        :items-per-page="18"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="primary" small @click="assignClient(item)"
            >Assign to client</v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="assignClientDialog" width="50vw">
      <v-card>
        <v-card-title>Assign to client</v-card-title>
        <v-card-text>
          <p>Assign the selected person to a client</p>
          <v-text-field
            label="Person name"
            v-model="selectedPersonName"
          ></v-text-field>

          <v-text-field
            label="Person Email"
            v-model="selectedPersonEmail"
          ></v-text-field>

          <v-select
            :items="clientQuickList"
            label="Select a client"
            v-model="selectedClient"
            item-text="title"
            item-value="clientId"
          >
            <!-- Customizing how the selected item is displayed -->
            <template v-slot:selection="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.title }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  data.item.subTitle
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>

            <!-- Customizing how each item in the dropdown is displayed -->
            <template v-slot:item="data">
              <v-list-item v-bind="data.attrs" v-on="data.on">
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    data.item.subTitle
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
          <v-select
            :items="personTypes"
            label="Select a person type"
            v-model="selectedPersonType"
          ></v-select>
          <v-alert
            v-if="error"
            type="error"
            colored
            icon="mdi-alert"
            transition="scale-transition"
          >
            {{ error }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red"
            dark
            @click="
              assignClientDialog = false;
              clearForm();
              error = null;
            "
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="green" dark @click="assignClientToClient()"
            >Assign</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import msalAuthHandler from "vue-msal-wrapper";
export default {
  props: ["showReturnButton", "customStyle"],
  name: "UnassignedPersonViewer",
  components: {},
  computed: {
    unassignedPersonsComputed() {
      if (!this.search) return this.unassignedPersons;
      return this.unassignedPersons.filter((item) => {
        return item.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  data() {
    return {
      unassignedPersons: [],
      loading: false,
      assignClientDialog: false,
      headers: [
        { text: "users with no client links", value: "name" },
        { text: "actions", value: "actions", sortable: false },
      ],
      clientQuickList: [],
      personTypes: [],

      selectedPersonType: null,
      selectedClient: null,
      selectedPersonName: null,
      selectedPersonEmail: null,
      selectedPersonUserName: null,
      search: "",
      error: null,
    };
  },
  methods: {
    clearSearch() {
      this.search = "";
    },
    returnToClientList() {
      this.$router.push("/");
    },
    assignClient(person) {
      this.selectedPersonUserName = person.name;
      this.assignClientDialog = true;
    },
    clearForm() {
      this.selectedPersonName = null;
      this.selectedPersonEmail = null;
      this.selectedClient = null;
      this.selectedPersonType = null;
      this.selectedPersonUserName = null;
    },
    assignClientToClient() {
      // if any field is empty, return
      this.error = null;
      if (
        !this.selectedClient ||
        !this.selectedPersonName ||
        !this.selectedPersonType ||
        !this.selectedPersonUserName
      ) {
        this.error = "Please fill in all fields";
        return;
      }
      this.loading = true;
      if (
        this.selectedPersonEmail === null ||
        this.selectedPersonEmail === ""
      ) {
        this.selectedPersonEmail = "temp@placeholder.com";
      }

      msalAuthHandler
        .authenticatedApi(this.$store.state.apiUrl, "backend")
        .post(
          `linkPersonToClient`,
          JSON.stringify({
            clientId: this.selectedClient,
            name: this.selectedPersonName,
            email: this.selectedPersonEmail,
            type: this.selectedPersonType,
            accessUserName: this.selectedPersonUserName,
          })
        )
        .then((response) => {
          if (this.$store.state.activeClient) {
            this.$store.state.activeClient.people = response.data.data;
          }
          this.loading = false;
          this.assignClientDialog = false;
          this.clearForm();
          // drop the person from the list
          this.unassignedPersons = this.unassignedPersons.filter(
            (person) => person.name !== this.selectedPersonUserName
          );
        });
    },
  },
  mounted() {
    this.loading = true;
    Promise.all([
      msalAuthHandler
        .authenticatedApi(this.$store.state.apiUrl, "backend")
        .get(`unassociatedDomainPersons`, {}),
      msalAuthHandler
        .authenticatedApi(this.$store.state.apiUrl, "backend")
        .get(`clientQuickList`, {}),
      msalAuthHandler
        .authenticatedApi(this.$store.state.apiUrl, "backend")
        .get(`personTypes`, {}),
    ])
      .then(([unassignedPersonsData, clientsData, personTypes]) => {
        this.unassignedPersons = unassignedPersonsData.data.data.map(
          (person) => {
            return {
              name: person,
            };
          }
        );
        this.clientQuickList = clientsData.data.data.map((client) => {
          return {
            clientId: client.id,
            title: client.title.split(" | ")[0],
            subTitle: client.title.split(" | ")[1],
          };
        });
        this.personTypes = personTypes.data.data;
        this.loading = false;
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>
