<template>
  <v-container fluid>
    <ClientList :clientListData="clients" :loading="loading"></ClientList>
  </v-container>
</template>

<script>
import ClientList from "../components/ClientList";
import msalAuthHandler from "vue-msal-wrapper";

export default {
  name: "ClientSelectorView",

  components: {
    ClientList,
  },
  data() {
    return {
      clients: [],
      loading: false,
    };
  },
  created() {
    this.loading = true;
    msalAuthHandler
      .authenticatedApi(this.$store.state.apiUrl, "backend")
      .get(`clients`, {})
      .then((res) => {
        this.clients = res.data.data;
        this.loading = false;
      });
  },
  watch: {
    "$store.state.refreshClientListData": function () {
      if (this.$store.state.refreshClientListData == true) {
        this.loading = true;
        msalAuthHandler
          .authenticatedApi(this.$store.state.apiUrl, "backend")
          .get(`clients`, {})
          .then((res) => {
            this.clients = res.data.data;
            this.$store.state.refreshClientListData = false;
            this.loading = false;
          });
      }
    },
  },
};
</script>
