<template>
    <div class="not-found">
        <h1>404 - Page Not Found</h1>
        <p>The page you are looking for does not exist.</p>
    </div>
</template>

<script>
export default {
    // Component configuration
}
</script>

<style scoped>
.not-found {
    text-align: center;
    margin-top: 100px;
}
</style>

