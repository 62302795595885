<template>
  <div>
    <v-btn
      v-if="yearListComputed.length > 0"
      @click="fetchYearData('all')"
      :disabled="usageDataLoadingComputed"
      :color="getColor('all')"
      :dark="checkIfActive('all') && !usageDataLoadingComputed"
      style="margin-right: 10px"
    >
      All Time
    </v-btn>
    <v-btn
      v-for="year in yearListComputed"
      :key="year.yearString"
      @click="fetchYearData(year)"
      :disabled="usageDataLoadingComputed"
      :color="getColor(year)"
      :dark="checkIfActive(year) && !usageDataLoadingComputed"
      style="margin-right: 10px"
    >
      {{ year.yearString }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ["yearList", "usageDataLoading"],
  data: () => ({
    dialogHeading: "",
    selectedYear: "all",
  }),
  mounted() {},
  computed: {
    yearListComputed() {
      return this.yearList || [];
    },
    usageDataLoadingComputed() {
      return this.usageDataLoading || false;
    },
  },
  methods: {
    checkIfActive(year) {
      if (year === "all" && this.selectedYear === "all") {
        return true;
      } else if (year === this.selectedYear) {
        return true;
      } else {
        return false;
      }
    },
    fetchYearData(item) {
      this.selectedYear = item;
      this.$store.state.dataYear = item;
    },
    getColor(year) {
      if (year === "all" && this.selectedYear === "all") {
        return "red";
      } else if (year === this.selectedYear) {
        return "red";
      } else {
        return "default";
      }
    },
  },
};
</script>
