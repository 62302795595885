<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="tableData"
      :item-key="uniqueIdentifier"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon color="red" small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar v-model="updatingLoading">
      {{ loadingText }}
    </v-snackbar>
  </div>
</template>

<script>
import msalAuthHandler from "vue-msal-wrapper";
export default {
  props: [
    "headers",
    "tableData",
    "storeKey",
    "uniqueIdentifier",
    "disabled",
    "endpoint",
  ],
  data() {
    return {
      updatingLoading: false,
      loadingText: `Removing ${this.endpoint}...`,
    };
  },
  methods: {
    deleteItem(item) {
      if (this.disabledComputed) {
        return;
      }
      this.updatingLoading = true;

      msalAuthHandler
        .authenticatedApi(this.$store.state.apiUrl, "backend")
        .delete(`${this.endpoint}/${item.id}`, {})
        .then((response) => {
          this.$store.state.activeClient[this.storeKey] =
            this.$store.state.activeClient[this.storeKey].filter(
              (i) => i.id !== item.id
            );
          this.updatingLoading = false;
        });
    },
  },
  computed: {
    disabledComputed() {
      return this.disabled;
    },
  },
};
</script>
