import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // apiKey: "vGK1x9xyjFz-Lp8_sVEpFiHGwVOPVYcfkKMHBSM8fmwFAzFuXN3NKQ==",
        // apiUrl: "https://strutt-invoicing-backend.azurewebsites.net/api/v1/",
        // apiUrl: "http://localhost:3000/",
        
        apiUrl: "https://strutt-invoicing-backend-v2.azurewebsites.net/",

        maintenance: false, // change this before deployment

        test: "hello vuex",
        refreshClientListData: false,
        dataYear: "all",

        activeClient: {
            id: 0,
            clientName: "",
            jobCode: [],
            domains: [],
            people: [],
            billingMonth: "",
            inactiveFlag: null,
            dataYear: "all",
        },
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
})
