import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import msalAuthHandler from "vue-msal-wrapper";
import authConfig from "@/authConfig";
Vue.use(msalAuthHandler, authConfig);


import { BackendAuth } from "./plugins/backendAuthentication";
Vue.use(BackendAuth);

import echarts from "echarts";
Vue.prototype.$echarts = echarts;

import chartsVue from "@carbon/charts-vue";

Vue.use(chartsVue);

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: function(h) { return h(App) }
}).$mount('#app')
