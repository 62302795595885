<template>
  <v-container fluid>
    <v-card style="height: 90vh; overflow: auto">
      <v-text-field
        outlined
        v-model="search"
        label="Filter clients"
        single-line
        clearable
        @click:clear="clearSearch"
        hide-details
      ></v-text-field>
      <v-data-table
        :loading="loading"
        loader-height="0"
        :headers="headers"
        :items="clientList"
        :search="search"
        :items-per-page="-1"
        dense
        @click:row="viewClientData"
        :hide-default-footer="true"
        sort-by="monthsTilRenewal"
        :sort-desc="false"
      >
        <template v-slot:top>
          <div
            class="text-center"
            style="padding-top: 8px; padding-bottom: 8px"
          >
            <v-btn
              color="blue"
              dark
              @click="createNewClientDialogToggle"
              style="margin-right: 8px"
            >
              <v-icon>mdi-plus</v-icon>
              Create new client
            </v-btn>
            <v-btn color="red" dark @click="viewUnassignedData">
              <v-icon>mdi-magnify-expand</v-icon>
              View unallocated
            </v-btn>
          </div>
        </template>
        <template v-slot:item.name="{ item }">
          <v-list-item-content>
            <v-list-item-title
              :class="getStyle(item)"
              v-text="item.name"
            ></v-list-item-title>
            <v-list-item-subtitle
              :class="getStyle(item)"
              v-text="splitJobCodes(item.jobcodes)"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <template v-slot:item.missingData="{ item }">
          <v-list-item-avatar>
            <v-icon large color="orange" v-if="item.missingData"
              >mdi-alert-outline</v-icon
            >
          </v-list-item-avatar>
        </template>
        <template v-slot:item.suffix="{ item }">
          <v-list-item-content>
            <v-list-item-title
              :class="getStyle(item)"
              v-text="item.jobcodes[0].slice(-2)"
            ></v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-slot:item.currentLicenses="{ item }">
          <v-list-item-content>
            <v-list-item-title
              :class="getStyle(item)"
              v-text="item.licenceCount"
            ></v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-slot:item.renewalMonth="{ item }">
          <v-list-item-content>
            <v-list-item-title
              :class="getStyle(item)"
              v-text="item.renewalMonth"
            ></v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-slot:item.monthsTilRenewal="{ item }">
          <v-list-item-content>
            <v-list-item-title
              :class="getStyle(item)"
              v-if="item.renewalMonth"
              v-text="item.monthsTilRenewal"
            ></v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-slot:item.monthsSinceLastInvoice="{ item }">
          <v-list-item-content>
            <v-list-item-title
              :class="getStyle(item)"
              v-if="item.renewalMonth"
              v-text="item.monthsSinceLastInvoice"
            ></v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-slot:item.invoicingStatus="{ item }">
          <v-list-item-content>
            <v-list-item-title
              :class="getStyle(item)"
              v-if="item.renewalMonth"
              v-text="item.invoicingStatus"
            ></v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-slot:loading>
          <div style="padding: 20px">
            <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <h1>Loading...</h1>
          </div>
        </template>
        <template v-slot:no-data> Sorry, nothing to display here :( </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="createNewClientDialog" max-width="600px">
      <v-card>
        <v-card-title>Create New Client</v-card-title>
        <v-card-text v-if="loading"
          ><v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <h1>Loading...</h1></v-card-text
        >
        <v-card-text v-if="!loading">
          <v-autocomplete
            v-model="newJobCode"
            :items="unusedJobCodes"
            label="select unused job code"
            dense
            outlined
            item-value="value"
            item-text="display"
          >
          </v-autocomplete>
          <v-autocomplete
            v-model="newDomain"
            :items="unusedDomains"
            label="select unused domain"
            dense
            multiple
            outlined
          >
          </v-autocomplete>
          <v-select
            v-model="newRenewalMonth"
            :items="billingMonths"
            label="Renewal Month"
            dense
            outlined
          ></v-select>
          <v-alert v-if="error" type="error">{{ error }}</v-alert>
        </v-card-text>
        <v-card-actions v-if="!loading">
          <v-btn
            color="red"
            dark
            @click="
              createNewClientDialogToggle();
              error = null;
            "
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            dark
            @click="createNewClient(newDomain, newJobCode, newRenewalMonth)"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import msalAuthHandler from "vue-msal-wrapper";
export default {
  props: ["clientListData", "loading"],
  data() {
    return {
      search: "",
      createNewClientDialog: false,
      error: null,
      newJobCode: null,
      newDomain: null,
      newRenewalMonth: null,
      unusedJobCodes: [],
      unusedDomains: [],
      // loading: false,
      headers: [
        {
          text: "Incomplete Data",
          value: "missingData",
        },
        // {
        //   text: "Suffix",
        //   value: "suffix",
        // },
        {
          text: "Client Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Current Licenses",
          value: "currentLicenses",
        },
        {
          text: "Renewal Month",
          value: "renewalMonth",
        },
        {
          text: "Months til Renewal Due",
          value: "monthsTilRenewal",
        },
        {
          text: "Months since last invoice",
          value: "monthsSinceLastInvoice",
        },
        {
          text: "Invoice Status",
          value: "invoicingStatus",
        },
      ],
      billingMonths: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  computed: {
    clientList() {
      if (!this.search) return this.clientListData;
      return this.clientListData.filter((item) => {
        return (
          item.name.toLowerCase().includes(this.search.toLowerCase()) ||
          String(item.jobcodes).includes(this.search.toLowerCase())
        );
      });
    },
  },
  mounted() {
    Promise.all([
      msalAuthHandler
        .authenticatedApi(this.$store.state.apiUrl, "backend")
        .get(`unusedJobcodes`, {}),
      msalAuthHandler
        .authenticatedApi(this.$store.state.apiUrl, "backend")
        .get(`unusedDomains`, {}),
    ])
      .then(([jobcodesData, domainsData]) => {
        this.unusedJobCodes = jobcodesData.data.data;
        this.unusedDomains = domainsData.data.data;
        console.log(this.unusedJobCodes);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    createNewClient(domain, jobCode, renewalMonth) {
      this.error = null;
      if (!jobCode || !renewalMonth) {
        this.error = "Please fill in the Jobcode and renewal month fields";
        return;
      }
      this.loading = true;
      msalAuthHandler
        .authenticatedApi(this.$store.state.apiUrl, "backend")
        .post(
          `clients`,
          JSON.stringify({
            domains: domain,
            jobcodes: jobCode,
            billingMonth: renewalMonth,
          })
        )
        .then((response) => {
          this.newDomain = null;
          this.newJobCode = null;
          this.newRenewalMonth = null;
          this.loading = false;
          this.createNewClientDialogToggle();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createNewClientDialogToggle() {
      this.createNewClientDialog = !this.createNewClientDialog;
    },
    getMonthNumber(monthString) {
      let monthNumber = 0;
      // switch statement that takes a month string and returns the month number
      switch (monthString) {
        case "January":
          monthNumber = 1;
          break;
        case "February":
          monthNumber = 2;
          break;
        case "March":
          monthNumber = 3;
          break;
        case "April":
          monthNumber = 4;
          break;
        case "May":
          monthNumber = 5;
          break;
        case "June":
          monthNumber = 6;
          break;
        case "July":
          monthNumber = 7;
          break;
        case "August":
          monthNumber = 8;
          break;
        case "September":
          monthNumber = 9;
          break;
        case "October":
          monthNumber = 10;
          break;
        case "November":
          monthNumber = 11;
          break;
        case "December":
          monthNumber = 12;
          break;
      }
      return monthNumber;
    },
    clearSearch() {
      this.search = "";
    },
    splitJobCodes(jobcodes) {
      let result = "";
      let i = 0;
      jobcodes.forEach((element) => {
        if (i == jobcodes.length - 1) {
          result += element;
        } else {
          result += element + ", ";
        }
        i++;
      });
      return result;
    },
    viewUnassignedData() {
      this.$router.push("/unassigned");
    },
    viewClientData(client) {
      this.$store.state.activeClient.jobcodes = client.jobcodes;
      this.$router.push(`/details/${client.id}`);
    },
    getStyle(item) {
      if (item.invoicingStatus === "Up to date") {
        return "paid";
      }
      if (item.invoicingStatus === "Invoiced") {
        return "paid";
      }

      if (item.invoicingStatus === "Payment Overdue") {
        return "notPaid";
      }
      if (item.invoicingStatus === "Invoice Overdue") {
        return "overdue";
      }
      if (
        item.invoicingStatus === "Inactive" ||
        item.invoicingStatus === "Unknown"
      ) {
        return "inactive";
      }
    },
  },
};
</script>

<style>
.overdue {
  color: red;
}

.incomplete {
  color: black;
}

.inactive {
  color: grey;
}

.notPaid {
  color: orange;
}

.paid {
  color: green;
}
</style>
