export default {
    msalConfig: {
        auth: {
            clientId: "c25b970a-1729-40a1-872d-1085d58fc5a0",
            authority:
                "https://login.microsoftonline.com/4ae48b41-0137-4599-8661-fc641fe77bea",
            redirectUri: window.location.origin + "/login/aad",
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: false,
        },
    },
    tokenTypes: {
        login: {
            scopes: ["User.Read"],
            extraScopesToConsent: [
                "api://c25b970a-1729-40a1-872d-1085d58fc5a0/invoicing-service",
                "https://arup.onmicrosoft.com/AIS/user_impersonation",
                "api://9cd3e861-6c25-49fd-a180-eb648cc9fe68/user_impersonation",
            ],
        },
        backend: {
            scopes: ["api://9cd3e861-6c25-49fd-a180-eb648cc9fe68/user_impersonation"],
        }
    },
};
