<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <msal-wrapper>
      <v-app-bar color="primary" app dense>
        <v-btn color="primary" elevation="0" dark @click="redirectHome()">
          Strutt Invoicing
        </v-btn>
      </v-app-bar>
      <v-main>
        <router-view />
      </v-main>
    </msal-wrapper>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({}),
  mounted() {
    if (this.$store.state.maintenance) {
      this.$router.push("/maintenance");
    }
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    redirectHome() {
      this.$router.push("/");
    },
  },
};
</script>
