<template>
  <v-container fluid>
    <v-card class="">
      <v-card-text v-if="loading">
        <div style="padding: 20px" class="d-flex justify-center align-center">
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <h1>Loading...</h1>
        </div>
      </v-card-text>
      <v-card-text v-if="!loading">
        <v-row>
          <v-col
            v-if="this.client"
            cols="9"
            class="d-flex justify-left align-center"
          >
            <h3>
              {{ client.name }} |
              <span v-for="(jobcode, index) in jobcodes" :key="jobcode">
                {{ jobcode }}{{ index !== jobcodes.length - 1 ? "," : "" }}
              </span>
              <v-btn color="primary" dark @click="linkJNDialogToggle()">
                <v-icon>mdi-plus</v-icon>
                link JN
              </v-btn>
            </h3>
          </v-col>
          <v-col cols="1" v-if="this.client">
            <EditDialog :client="this.client"> </EditDialog>
          </v-col>
          <v-col cols="2">
            <v-btn color="red" dark @click="returnToClientList()">
              <v-icon>mdi-arrow-left</v-icon>
              BACK TO LIST
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="justify-left align-center">
            <strong>Domains</strong>
            <div v-for="domain in domains" :key="domain.domain">
              {{ domain.domain }}
            </div>
            <div v-if="domains.length === 0" style="color: red">
              No domains assigned... assign a domain to see usage stats
            </div>
          </v-col>
          <v-col cols="8" class="justify-left align-center">
            <v-row>
              <v-col>
                <v-btn
                  :disabled="invoiceContact.length === 0"
                  :href="generateMailtoLink"
                  target="_blank"
                  color="primary"
                >
                  Send Email
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <strong>Invoice Contact</strong>
                <div v-for="person in invoiceContact" :key="person.email">
                  {{ person.email }}
                </div>
                <div v-if="invoiceContact.length === 0" style="color: red">
                  No people assigned
                </div>
              </v-col>
              <v-col cols="4">
                <strong>Invoice CC</strong>
                <div v-for="person in invoiceCC" :key="person.email">
                  {{ person.email }}
                </div>
                <div v-if="invoiceCC.length === 0" style="color: red">
                  No people assigned
                </div>
              </v-col>
              <v-col cols="4">
                <strong>Distribution</strong>
                <div v-for="person in distribution" :key="person.email">
                  {{ person.email }}
                </div>
                <div v-if="distribution.length === 0" style="color: red">
                  No people assigned
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card flat>
              <!-- <v-row v-if="!inputRequired">
                <v-col>
                  <v-alert outlined type="warning" prominent border="left">
                    There is missing data for this client. Use the "EDIT" button
                    at the top right to complete this client's entry.
                  </v-alert>
                </v-col>
              </v-row> -->
              <v-card-text>
                <ClientInvoices
                  v-if="clientInvoiceDataComputed"
                  :loading="invoiceLoading"
                  :clientInvoices="clientInvoiceDataComputed"
                />
              </v-card-text>
              <v-card-text>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    padding-bottom: 20px;
                  "
                >
                  <YearSelector
                    :yearList="yearOptionsComputed"
                    :usageDataLoading="userDataLoading"
                  />
                  <v-progress-circular
                    v-if="userDataLoading"
                    :size="20"
                    :width="3"
                    color="primary"
                    indeterminate
                    style="margin-left: 5px; margin-right: 5px"
                  ></v-progress-circular>
                  <div v-if="userDataLoading" style="margin-left: 5px">
                    Loading usage data...
                  </div>
                </div>

                <ClientGraph :clientUsageData="clientUsageDataComputed" />
              </v-card-text>
              <!-- <v-card-text v-if="domains.length !== 0"> -->
              <v-card-text>
                <v-row>
                  <v-col cols="1" />
                  <v-col cols="10" class="justify-left align-center">
                    <UserUsageData :clientUsageData="clientUsageDataComputed" />
                  </v-col>
                  <v-col cols="1" />
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="linkJNDialog" max-width="500">
      <v-card>
        <v-card-title>Link JN</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="tempClient"
                :items="unusedJobCodes"
                label="select unused job code"
                dense
                outlined
                item-value="value"
                item-text="display"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="linkJNDialogToggle()">Close</v-btn>
          <v-btn color="primary" @click="linkJN()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import msalAuthHandler from "vue-msal-wrapper";
import EditDialog from "./EditDialog";
import ClientGraph from "./ClientGraph";
import ClientInvoices from "./ClientInvoices";
import YearSelector from "./YearSelector";
import UserUsageData from "./UserUsageData";

export default {
  components: {
    EditDialog,
    ClientGraph,
    ClientInvoices,
    YearSelector,
    UserUsageData,
  },
  data() {
    return {
      tab: null,
      items: [],
      loading: false,
      invoiceLoading: false,
      clientUsageData: [],
      clientInvoiceData: [],
      yearOptions: [],
      client: null,
      userDataLoading: false,
      linkJNDialog: false,
      unusedJobCodes: [],
      tempClient: null,
    };
  },
  mounted() {
    this.loadClientData();
    msalAuthHandler
      .authenticatedApi(this.$store.state.apiUrl, "backend")
      .get(`unusedJobcodes`, {})
      .then((response) => {
        this.unusedJobCodes = response.data.data;
      });
  },
  watch: {
    "$store.state.dataYear": function () {
      this.userDataLoading = true;
      let dataYear = this.$store.state.dataYear;
      if (dataYear === "all") {
        msalAuthHandler
          .authenticatedApi(this.$store.state.apiUrl, "backend")
          .get(`accessSnapshot/${this.clientId}/${null}/${null}`, {})
          .then((response) => {
            this.clientUsageData = response.data.data;
            // do check here if 200, do below, if 400, return empty clientUsageData

            this.clientUsageData.access.forEach((element) => {
              element["group"] = "function calls";
              let date = new Date(element.EffectiveFrom * 1000);
              element["date"] = date.toISOString();
            });
            this.userDataLoading = false;
          });
      } else {
        msalAuthHandler
          .authenticatedApi(this.$store.state.apiUrl, "backend")
          .get(
            `accessSnapshot/${this.clientId}/${this.client.renewalMonth}/${dataYear.yearInt}`,
            {}
          )
          .then((response) => {
            this.clientUsageData = response.data.data;
            // do check here if 200, do below, if 400, return empty clientUsageData

            this.clientUsageData.access.forEach((element) => {
              element["group"] = "function calls";
              let date = new Date(element.EffectiveFrom * 1000);
              element["date"] = date.toISOString();
            });
            this.userDataLoading = false;
          });
      }
    },
  },
  computed: {
    generateMailtoLink() {
      const subject = "Strutt invoice";
      const body = "";

      // Encode the email addresses and other parameters
      if (this.invoiceContact.length === 0) {
        return "";
      }
      const toEncoded = encodeURIComponent(
        this.invoiceContact.map((person) => person.email)
      );
      const ccEncoded = this.invoiceCC.map((person) => person.email);
      const subjectEncoded = encodeURIComponent(subject);
      const bodyEncoded = encodeURIComponent(body);

      // Construct the "mailto" link
      if (ccEncoded.length > 0) {
        return `mailto:${toEncoded}?cc=${ccEncoded}&subject=${subjectEncoded}&body=${bodyEncoded}`;
      } else {
        return `mailto:${toEncoded}?subject=${subjectEncoded}&body=${bodyEncoded}`;
      }
    },
    domains() {
      if (this.client) {
        if (this.client.domains) {
          return this.client.domains;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    people() {
      if (this.client) {
        if (this.client.people) {
          return this.client.people;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    invoiceContact() {
      if (this.people) {
        return this.people.filter((person) => person.type === "INVOICE");
      } else {
        return [];
      }
    },
    invoiceCC() {
      if (this.people) {
        return this.people.filter((person) => person.type === "INVOICECC");
      } else {
        return [];
      }
    },
    distribution() {
      if (this.people) {
        return this.people.filter((person) => person.type === "DISTRIBUTION");
      } else {
        return [];
      }
    },
    users() {
      if (this.people) {
        return this.people.filter((person) => person.type === "USER");
      } else {
        return [];
      }
    },
    jobcodes() {
      if (this.client) {
        if (this.client.jobcodes) {
          return this.client.jobcodes;
        } else {
          return [];
        }
      }
    },
    clientInvoiceDataComputed() {
      if (this.client) {
        return this.client.invoices;
      }
    },
    clientUsageDataComputed() {
      return this.clientUsageData;
    },
    inputRequired() {
      if (this.client) {
        return this.client.renewalMonth && this.client.domains;
      } else {
        return true;
      }
    },
    yearOptionsComputed() {
      return this.yearOptions;
    },
  },
  methods: {
    linkJN() {
      let jn = "";

      // if tempClient is an array, get the first item
      if (Array.isArray(this.tempClient)) {
        jn = this.tempClient[0];
      } else {
        console.log("error - not an array");
      }

      msalAuthHandler
        .authenticatedApi(this.$store.state.apiUrl, "backend")
        .post(
          `jobcode`,
          JSON.stringify({
            jobcode: jn,
            clientId: this.client.id,
          })
        )
        .then((response) => {
          console.log(response.data);
        });
      this.linkJNDialogToggle();
    },
    linkJNDialogToggle() {
      this.tempClient = null;
      this.linkJNDialog = !this.linkJNDialog;
    },
    returnToClientList() {
      this.$router.push("/");
    },

    loadClientData() {
      // get the variable "id" from the URL params
      this.clientId = this.$route.params.id;
      this.loading = true;
      this.invoiceLoading = true;
      this.tab = 0;

      Promise.all([
        msalAuthHandler
          .authenticatedApi(this.$store.state.apiUrl, "backend")
          .get(`clients/${this.clientId}`, {})
          .then((response) => {
            this.client = response.data.data;
            this.$store.state.activeClient = this.client;
            this.invoiceLoading = false;
          }),

        msalAuthHandler
          .authenticatedApi(this.$store.state.apiUrl, "backend")
          .get(`accessSnapshot/${this.clientId}/${null}/${null}`, {})
          .then((response) => {
            this.clientUsageData = response.data.data;
            // do check here if 200, do below, if 400, return empty clientUsageData

            this.clientUsageData.access.forEach((element) => {
              element["group"] = "function calls";
              let date = new Date(element.EffectiveFrom * 1000);
              element["date"] = date.toISOString();
            });
            this.loading = false;
          }),

        msalAuthHandler
          .authenticatedApi(this.$store.state.apiUrl, "backend")
          .get(`clientActiveYears/${this.clientId}`, {})
          .then((response) => {
            if (
              typeof response.data === "string" ||
              response.data instanceof String
            ) {
              this.yearOptions = [];
            } else {
              this.yearOptions = response.data.data;
            }
          }),
      ])
        .then(() => {
          // All requests completed
          // Add any additional logic here
        })
        .catch((error) => {
          // Handle errors
          console.error(error);
        });
    },
  },
};
</script>
